
import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend<Data, Computed, Methods>({
  name: 'LoginLandingPage',
  async mounted() {
    // delete log
    if (localStorage.getItem('formalRoot') || this.deviceInfo) {
      this.$naver.click()
      localStorage.removeItem('formalRoot')
    } else {
      this.$closeSubWebView()
      await window.close()
      this.$router.push('/')
    }
  },
  computed: {
    ...mapState('DeviceStore', ['deviceInfo']),
  },
})

interface Data {}
interface Computed {
  deviceInfo: DeviceInfo
}
interface Methods {}
